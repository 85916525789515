import { Atom } from ":mods";

export function createExperienceInfoInputs() {
  const JobTitle = Atom.Form.createInput("", {
    required: false,
  });
  const MuseumWork = Atom.Form.createInput("", {
    required: false,
  });
  const DepartmentWork = Atom.Form.createInput("", {
    required: false,
  });
  const CurrentlyWorked = Atom.Form.createInput("", {
    required: false,
  });
  const YearsWorked = Atom.Form.createInput("", {
    required: false,
  });

  const Actions = Atom.Form.createFormActions({
    JobTitle,
    MuseumWork,
    DepartmentWork,
    CurrentlyWorked,
    YearsWorked,
  });
  return {
    Actions,
    JobTitle,
    MuseumWork,
    DepartmentWork,
    CurrentlyWorked,
    YearsWorked,
  };
}
